<template>
  <div>
    <el-card class="margin-x">
      <el-page-header @back="goBack" :content="$t('答案详情')">
      </el-page-header>


      <div v-loading="loading" class="padding margin-t">
        <el-empty v-if="list.length == 0"></el-empty>
        <div v-if="list.length > 0" class="margin-b">
          <el-checkbox @change="allChange" v-model="checkedAll">全选</el-checkbox>
        </div>
        <div v-for="(item, index) in list" :key="index" :class="{ 'margin-b': index < list.length - 1 }">
          <template>
            <div class="flex flex-between flex-v-center" style="height:100%">
              <el-checkbox @change="refreshCheck" v-model="item.checked" class="margin-r"></el-checkbox>
              <div class="flex flex-between flex1 mock-item on" @click="showRecord(item)">
                <div class="flex flex-v-center">
                  <h5 class="margin-rs">{{ `${index + 1}. #${item.source.no}` }}</h5>
                  <el-tag size="mini" v-if="item.source.level == 2">{{ $t('难') }}</el-tag>
                  <el-tag size="mini" v-else-if="item.source.level == 1">{{ $t('中') }}</el-tag>
                  <el-tag size="mini" v-else-if="item.source.level == 0">{{ $t('简') }}</el-tag>
                </div>
                <div class="flex flex-v-center">
                  <div style="font-size: 12px;margin-left:8px;">{{ $t('得分') }}：{{ item.score }} / {{
                    item.total
                    }}</div>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="margin-x margin-t st" style="padding-left: 15px;">{{ $t('总得分') }}：{{ score }} / {{ total }}</div>
      </div>

      <div>
        <el-divider></el-divider>
        <div class="center margin-t">
          <el-button class="margin-r" :disabled="ids.length == 0" @click="reDo()" round type="primary">{{ $t('重刷一遍')
            }}<i class="el-icon-refresh"></i></el-button>
          <el-button class="margin-l" @click="next()" round type="primary">{{ $t('刷下一组题目') }}<i
              class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </el-card>

  </div>
</template>
<script>

export default {
  data() {

    return {
      checkedAll: false,
      loading: false,
      list: [],
      ids: [],
      info: null,
      total: 0,
      score: 0,
      lastPath: ''
    };
  },

  watch: {
    '$route.query': {
      handler() {
        console.log(this.lastPath)
        if (this.$route.path == '/main/immersive/record') {
          if (this.lastPath != '/record') {
            this.query()
          }
        } else {
          this.lastPath = this.$route.path
        }
      },
      immediate: false
    }
  },

  created() {
    this.query();
  },

  methods: {
    reDo() {
      this.$router.push({ path: '/main/pte', query: { tag: this.info.tag, immersive_id: this.$route.query.id, source_index: 0, source_ids: this.ids.join(',') } })
    },
    allChange(val) {
      this.list.forEach((item) => {
        item.checked = val;
      });
      this.refreshCheck();
    },
    next() {
      this.$router.replace({ path: '/main/immersive', query: { tag: this.info.tag, type: this.info.type, num: this.info.num } });
    },

    refreshCheck() {
      this.ids = this.list.filter((item) => item.checked).map((item) => item.source.id);
    },

    getTotal(item) {
      let total = 0;
      if (['RA', 'RS', 'DI', 'RL'].indexOf(item.tag) != -1) {
        total = 90;
      } else if (item.tag == 'ASQ') {
        total = 1;
      } else if (item.tag == 'SWT') {
        total = 7;
      } else if (item.tag == 'WE') {
        total = 15;
      } else if (item.tag == 'SST') {
        total = 10;
      } else if (item.tag == 'WFD') {
        total = item.answer.split(' ').length;
      } else if (item.tag == 'RO') {
        total = item.answer.split(',').length - 1;
      } else {
        total = item.answer.split(',').length;
      }
      return total;
    },
    showRecord(item) {
      this.$router.push({
        path: '/record',
        query: { id: item.id, immersive: 1 }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    query() {
      this.loading = true
      this.$http.post("/api/immersive/records", {
        source_ids: this.$route.query.source_ids,
        id: this.$route.query.id,
      }).then(list => {
        let score = 0;
        let total = 0;
        list.forEach((item) => {
          item.tag = item.level == 2 ? '难' : item.level == 2 ? '中' : '简'
          item.total = this.getTotal(item.source)
          score += item.score
          total += item.total
        });

        if (['RA', 'RS', 'DI', 'RL', 'SWT', 'WE', 'SST'].indexOf(list[0].source.tag) != -1) {
          this.total = list[0].total
          this.score = Math.round(score / list.length)
        } else {
          this.total = total
          this.score = score
        }


        this.loading = false
        this.list = list
      })


      this.$http.post("/api/immersive/info", {
        id: this.$route.query.id,
      }).then(info => {
        this.info = info
      })
    },
  },
};
</script>

<style scope>
.mock-item {
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}



.mock-item:hover,
.mock-item.on {
  border: 1px solid #e6a13c;
  color: #e6a13c;
}
</style>
